import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative-form-div" },
    [
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("DXATextField", {
            staticClass: "my-4",
            attrs: {
              Field: _vm.$t("code"),
              Text: _vm.code,
              ErrorText: _vm.errorCode,
              "data-test": "Banker:CheckRegisterCode:InputCode",
            },
            on: {
              update: function (value) {
                _vm.code = value
              },
            },
          }),
        ],
        1
      ),
      _c(
        VRow,
        {
          class: _vm.gs.isMobile() ? "mt-0" : "mt-13",
          style:
            "flex-direction: " +
            (_vm.gs.isMobile() ? "row-reverse" : "column") +
            ";",
        },
        [
          _c(
            VCol,
            {
              staticClass: "pt-0",
              style:
                "display: flex; justify-content: " +
                (_vm.gs.isMobile() ? "end" : "start") +
                "; align-items: center",
              attrs: { cols: "6", md: "12" },
              on: {
                click: function ($event) {
                  return _vm.send_another_code()
                },
              },
            },
            [
              _c("a", { staticClass: "options-btn" }, [
                _vm._v(_vm._s(_vm.$t("resent_code"))),
              ]),
            ]
          ),
          _c(
            VCol,
            {
              staticClass: "pt-4",
              staticStyle: {
                display: "flex",
                "justify-content": "start",
                "align-items": "center",
              },
              attrs: { cols: "6", md: "12" },
              on: {
                click: function ($event) {
                  return _vm.$emit("edit_info")
                },
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "options-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("edit_info")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("edit_information_btn")))]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        VRow,
        {
          class: "ma-0 mt-2 mb-3 " + (_vm.gs.isMobile() ? "" : "bottom-row"),
          attrs: { justify: "start" },
        },
        [
          _c(
            VCol,
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "mx-0 my-1 mb-3 dxa-btn-primary-blue",
                  staticStyle: { height: "45px !important" },
                  attrs: {
                    "data-test": "Banker:CheckRegisterCode:NextStep",
                    loading: _vm.loading,
                    block: "",
                  },
                  on: { click: _vm.check_form },
                },
                [
                  _c("span", { staticStyle: { "font-size": "24px" } }, [
                    _vm._v(_vm._s(_vm.$t("next"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }