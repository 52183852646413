import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-left" },
    [
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c(
            VRow,
            { attrs: { justify: "start", align: "center" } },
            [
              _c(
                VCol,
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("DXATextField", {
                    staticClass: "customTextField",
                    attrs: {
                      "data-test": "banker:signup:first_name",
                      Field: _vm.$t("name"),
                      Text: _vm.User.Name,
                    },
                    on: {
                      update: function (value) {
                        _vm.User.Name = value
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("DXATextField", {
                    staticClass: "customTextField",
                    attrs: {
                      "data-test": "banker:signup:last_name",
                      Field: _vm.$t("last_name"),
                      Text: _vm.User.LastName,
                    },
                    on: {
                      update: function (value) {
                        _vm.User.LastName = value
                      },
                    },
                  }),
                ],
                1
              ),
              _c(VCol, { attrs: { ccols: "12", md: "6" } }, [
                _c(
                  "div",
                  { style: "height: " + (_vm.FullHeight ? "80%" : "auto") },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "dxa-text-field-label mb-1",
                        style: "color: " + _vm.color,
                        attrs: { id: "label" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("nationality")) + " ")]
                    ),
                    _c(VSelect, {
                      staticClass: "custom-select",
                      attrs: {
                        "data-test": "banker:signup:nationality",
                        rules: [_vm.required],
                        items: _vm.countries,
                        "background-color": "#e9e9e9",
                        dense: "",
                        filled: "",
                        "item-text": "name",
                        "return-object": "",
                      },
                      on: {
                        change: function (e) {
                          return _vm.nationality_code_changed(e)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function (data) {
                            return [
                              _c("img", {
                                staticStyle: { width: "18px", height: "auto" },
                                attrs: { src: data.item.flag, alt: "" },
                              }),
                              _c(
                                "span",
                                { staticClass: "text-combo-box mx-2" },
                                [_vm._v(_vm._s(data.item.name))]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item",
                          fn: function (data) {
                            return [
                              _c("img", {
                                staticStyle: { width: "18px", height: "auto" },
                                attrs: { src: data.item.flag, alt: "" },
                              }),
                              _c("span", { staticClass: "mx-2" }, [
                                _vm._v(_vm._s(data.item.name)),
                              ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.nationalitySelected,
                        callback: function ($$v) {
                          _vm.nationalitySelected = $$v
                        },
                        expression: "nationalitySelected",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(VCol, { attrs: { cols: "12", md: "6" } }),
              _c(
                VCol,
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("div", {
                    style: "height: " + (_vm.FullHeight ? "80%" : "auto"),
                  }),
                  _c(
                    "p",
                    {
                      staticClass: "dxa-text-field-label mb-1",
                      style: "color: " + _vm.color,
                      attrs: { id: "label" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("document_type")) + " ")]
                  ),
                  _c(
                    VRadioGroup,
                    {
                      staticStyle: { "margin-top": "4px" },
                      attrs: { row: "" },
                      model: {
                        value: _vm.User.RegisterDocumentType,
                        callback: function ($$v) {
                          _vm.$set(_vm.User, "RegisterDocumentType", $$v)
                        },
                        expression: "User.RegisterDocumentType",
                      },
                    },
                    _vm._l(_vm.docsType, function (docType) {
                      return _c(VRadio, {
                        key: docType.value,
                        staticStyle: {
                          display: "flex",
                          "align-itens": "center",
                        },
                        attrs: {
                          value: docType.value,
                          "data-test":
                            "banker:signup:documentType:" + docType.value,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "document-type-text" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.translateDocsType(docType))
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("DXATextField", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value:
                          _vm.User.RegisterDocumentType ==
                            _vm.documentTypeEnum.cpf ||
                          _vm.User.RegisterDocumentType ==
                            _vm.documentTypeEnum.socialNumber
                            ? "###.###.###-##"
                            : "",
                        expression:
                          "\n            User.RegisterDocumentType == documentTypeEnum.cpf ||\n            User.RegisterDocumentType == documentTypeEnum.socialNumber\n              ? '###.###.###-##'\n              : ''\n          ",
                      },
                    ],
                    staticClass: "customTextField",
                    attrs: {
                      "data-test": "banker:signup:social_number",
                      Field:
                        _vm.User.RegisterDocumentType ==
                        _vm.documentTypeEnum.socialNumber
                          ? _vm.$t("cpf")
                          : _vm.$t("passport"),
                      Text: _vm.User.SocialNumber,
                      ErrorText: _vm.errorSocialNumber,
                    },
                    on: {
                      text_changed: function ($event) {
                        return _vm.check_social_number()
                      },
                      update: function (value) {
                        _vm.User.SocialNumber = value
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("DXATextField", {
                    staticClass: "customTextField align top",
                    attrs: {
                      "data-test": "banker:signup:email",
                      Field: _vm.$t("email"),
                      Text: _vm.User.Email,
                      ErrorText: _vm.errorEmail,
                      Type: "email",
                    },
                    on: {
                      text_changed: function ($event) {
                        return _vm.check_email()
                      },
                      update: function (value) {
                        _vm.User.Email = value
                      },
                    },
                  }),
                  _c("br"),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    VRow,
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(VCol, { attrs: { cols: "3" } }, [
                        _c(
                          "div",
                          {
                            style:
                              "height: " + (_vm.FullHeight ? "80%" : "auto"),
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "dxa-text-field-label mb-1",
                                style: "color: " + _vm.color,
                                attrs: { id: "label" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("country_code")) + " "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "field-header" }),
                            _c(VAutocomplete, {
                              staticClass: "custom-select",
                              attrs: {
                                color: "black",
                                dense: "",
                                filled: "",
                                rounded: "",
                                rules: [
                                  function (v) {
                                    return (
                                      (!!v && _vm.User.CountryCode != null) ||
                                      _vm.required()
                                    )
                                  },
                                ],
                                items: _vm.countries,
                                "item-text": "name",
                                "return-object": "",
                                "data-test": "banker:signup:country_code",
                              },
                              on: { change: _vm.country_code_changed },
                              scopedSlots: _vm._u([
                                {
                                  key: "selection",
                                  fn: function (data) {
                                    return [
                                      _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          height: "auto",
                                        },
                                        attrs: { src: data.item.flag, alt: "" },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "text-combo-box mx-2" },
                                        [
                                          _vm._v(
                                            _vm._s(data.item.callingCodes[0])
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          height: "auto",
                                        },
                                        attrs: { src: data.item.flag, alt: "" },
                                      }),
                                      _c("span", { staticClass: "mx-2" }, [
                                        _vm._v(_vm._s(data.item.name)),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.User.CountryCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.User, "CountryCode", $$v)
                                },
                                expression: "User.CountryCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        VCol,
                        { staticClass: "pl-2", attrs: { cols: "9" } },
                        [
                          _c("DXATextField", {
                            staticClass: "customTextField",
                            attrs: {
                              "data-test": "banker:signup:phone",
                              Field: _vm.$t("celphone"),
                              Text: _vm.User.CelPhone,
                              ErrorText: _vm.errorPhone,
                              Mask:
                                _vm.User.CountryCode.callingCodes[0] == "55"
                                  ? "(##) #####-####"
                                  : "",
                              Type: "tel",
                            },
                            on: {
                              text_changed: function ($event) {
                                return _vm.check_phone()
                              },
                              update: function (value) {
                                _vm.User.CelPhone = value
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("DXATextField", {
                    staticClass: "customTextField",
                    attrs: {
                      "data-test": "banker:signup:password",
                      Field: _vm.$t("password"),
                      Text: _vm.User.UserPassword,
                      ErrorText: _vm.errorPassword,
                      Type: "password",
                    },
                    on: {
                      text_changed: function ($event) {
                        return _vm.check_password()
                      },
                      update: function (value) {
                        _vm.User.UserPassword = value
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("DXATextField", {
                    staticClass: "customTextField",
                    attrs: {
                      Field: _vm.$t("re_password"),
                      ErrorText: _vm.errorRePassword,
                      Text: _vm.rePassword,
                      Type: "password",
                      "data-test": "banker:signup:passwordConfirm",
                    },
                    on: {
                      text_changed: function ($event) {
                        return _vm.check_re_password()
                      },
                      update: function (value) {
                        _vm.rePassword = value
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(
                VCol,
                { attrs: { cols: "12" } },
                [
                  _c("ValidateStrengthPassword", {
                    attrs: { userPassword: _vm.User.UserPassword },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "my-4" }, [
            _c(
              "ul",
              [
                _vm._l(5, function (i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      staticStyle: {
                        "font-weight": "300",
                        "font-size": "12px",
                        "line-height": "15px",
                        color: "#9c9c9c",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("password_rule_" + i)) + " ")]
                  )
                }),
                _c(
                  "li",
                  {
                    staticStyle: {
                      "font-weight": "300",
                      "font-size": "12px",
                      "line-height": "15px",
                      color: "#9c9c9c",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("password_min_character")) + " ")]
                ),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "a",
        {
          staticClass: "my-4",
          staticStyle: { display: "flex", "align-items": "center" },
          on: { click: _vm.open_terms },
        },
        [
          _c(VCheckbox, {
            staticClass: "dxa-checkbox",
            attrs: {
              readonly: "",
              "hide-details": "",
              color: "#1A7DFF",
              "data-test": "banker:signup:terms",
            },
            on: { click: _vm.open_terms },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "mb-0 ml-2",
                        staticStyle: {
                          "font-size": "16px",
                          "line-height": "20px",
                          "text-decoration-line": "underline",
                          color: "#9c9c9c",
                        },
                        on: { click: _vm.open_terms },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("read_agreed") + " " + _vm.$t("terms_use")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.User.Terms,
              callback: function ($$v) {
                _vm.$set(_vm.User, "Terms", $$v)
              },
              expression: "User.Terms",
            },
          }),
        ],
        1
      ),
      _c(
        "a",
        {
          staticClass: "my-4",
          staticStyle: { display: "flex", "align-items": "center" },
          on: { click: _vm.open_confidenciality_terms },
        },
        [
          _c(VCheckbox, {
            staticClass: "dxa-checkbox",
            attrs: {
              readonly: "",
              "hide-details": "",
              color: "#1A7DFF",
              "data-test": "banker:signup:confidenciality",
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "mb-0 ml-2",
                        staticStyle: {
                          "font-size": "16px",
                          "line-height": "20px",
                          "text-decoration-line": "underline",
                          color: "#9c9c9c",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("read_agreed") +
                                " " +
                                _vm.$t("terms_confidenciality")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.User.ConfidencialityTerms,
              callback: function ($$v) {
                _vm.$set(_vm.User, "ConfidencialityTerms", $$v)
              },
              expression: "User.ConfidencialityTerms",
            },
          }),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.terms,
            callback: function ($$v) {
              _vm.terms = $$v
            },
            expression: "terms",
          },
        },
        [
          _c("TermosPDFModal", {
            key: _vm.terms,
            attrs: {
              type: "terms",
              Html: _vm.contractHtmlTerms,
              Header: _vm.$t("terms_use"),
              Subtitle: _vm.contractAgentTerms,
              "data-test": "banker:signup:terms_modal",
            },
            on: { agree: _vm.agree_terms, decline: _vm.decline_terms },
          }),
        ],
        1
      ),
      _vm.confidencialityTerms
        ? _c(
            VDialog,
            {
              attrs: { persistent: "" },
              model: {
                value: _vm.confidencialityTerms,
                callback: function ($$v) {
                  _vm.confidencialityTerms = $$v
                },
                expression: "confidencialityTerms",
              },
            },
            [
              _c("TermosConfidencialidadeModal", {
                key: _vm.confidencialityTerms,
                attrs: {
                  type: "confidenciality",
                  Html: _vm.contractHtmlConfidentialityTerm,
                  Header: _vm.$t("terms_confidenciality"),
                  Subtitle: _vm.contractAgentConfidentialityTerm,
                },
                on: {
                  agree: _vm.agree_confidenciality_terms,
                  decline: _vm.decline_confidenciality_terms,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        VRow,
        { staticClass: "ma-0 mt-2", attrs: { justify: "start" } },
        [
          _c(
            VCol,
            { staticClass: "pa-0", attrs: { cols: "12", md: "6" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "mx-0 my-1 mb-3 dxa-btn-primary-blue",
                  staticStyle: { height: "45px !important" },
                  attrs: {
                    "data-test": "banker:signup:submit",
                    loading: _vm.loading,
                    block: "",
                  },
                  on: { click: _vm.check_form },
                },
                [
                  _c("span", { staticStyle: { "font-size": "24px" } }, [
                    _vm._v(_vm._s(_vm.$t("agree_continue"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.fieldError
        ? _c(VAlert, { staticClass: "ma-2", attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.fieldError) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }