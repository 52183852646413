import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative-form-div" },
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            { attrs: { cols: "12", md: "6" } },
            [
              _c("DXATextField", {
                attrs: {
                  Field: _vm.$t("email"),
                  Text: _vm.user.Email,
                  ErrorText: _vm.errorEmail,
                  Type: "email",
                },
                on: {
                  text_changed: function ($event) {
                    return _vm.check_email()
                  },
                  update: function (value) {
                    _vm.user.Email = value
                  },
                },
              }),
            ],
            1
          ),
          !_vm.gs.isMobile()
            ? _c(VCol, { attrs: { cols: "12", md: "6" } })
            : _vm._e(),
          _c(
            VCol,
            { attrs: { cols: "12", md: "6" } },
            [
              _c("DXATextField", {
                attrs: {
                  Field: _vm.$t("celphone"),
                  Text: _vm.user.PhoneNumber,
                  ErrorText: _vm.errorPhone,
                  Mask: "(##) #####-####",
                  Type: "tel",
                },
                on: {
                  text_changed: function ($event) {
                    return _vm.check_phone()
                  },
                  update: function (value) {
                    _vm.user.PhoneNumber = value
                  },
                },
              }),
            ],
            1
          ),
          !_vm.gs.isMobile()
            ? _c(VCol, { attrs: { cols: "12", md: "6" } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        VRow,
        {
          class: "ma-0 mt-2 mb-3 " + (_vm.gs.isMobile() ? "" : "bottom-row"),
          attrs: {
            justify: _vm.gs.isMobile() ? "center" : "start",
            align: "center",
          },
        },
        [
          _c(
            VCol,
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "mx-0 my-1 dxa-btn-primary-blue",
                  staticStyle: { height: "45px !important" },
                  attrs: { loading: _vm.loading, block: "" },
                  on: { click: _vm.check_form },
                },
                [
                  _c("span", { staticStyle: { "font-size": "24px" } }, [
                    _vm._v(_vm._s(_vm.$t("save"))),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            VCol,
            {
              class: "text-" + (_vm.gs.isMobile() ? "center" : "start"),
              attrs: { cols: "12", md: "3" },
            },
            [
              _c(
                "a",
                {
                  staticStyle: {
                    "font-weight": "bold",
                    "font-size": "24px",
                    "line-height": "30px",
                    "text-align": "center",
                    "text-decoration-line": "underline",
                    color: "#1a7dff",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm.fieldError
        ? _c(VAlert, { staticClass: "ma-2", attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.fieldError) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }